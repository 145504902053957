:root {
  --blue: rgba(79, 192, 210, 1);
  --green: rgba(82, 210, 154, 1);
  --yellow: rgba(231, 196, 104, 0.7);
  --orange: rgba(235, 118, 85, 1);
  --dark-bg: rgba(0, 0, 0, 0.9);
  --light-bg: rgba(255, 255, 255, 0.3);
  --text: rgba(8, 28, 120, 0.9); /* Color con 90% de opacidad */
}
body {
  background: var(--dark-bg);
  font-family: "Open Sans", sans-serif;
}
section {
  background-color: transparent;
  margin-top: 15px;
  text-align: center;
}
.transitionLink a {
  text-decoration: none;
  transition: color 0.2s ease-out;
}
.transitionLink a {
  color: var(--text);
}

.transitionLink a:hover {
  color: rgba(63, 153, 168, 1); /* Aproximación de darken(var(--blue), 20%) */
}

.wrapper {
  width: 100%;
  margin: 20px auto 0px auto;
  padding: 0;
  background: var(--light-bg);
  color: var(--text);
  overflow: hidden;
  position: relative;
}
.row ul {
  margin-bottom: 5rem;
  padding: 0;
  height: 0;
}

.row ul li {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  list-style: none;
  display: inline-block;
  width: 22%;
  box-sizing: border-box;
}

@media only screen and (max-width: 767px) and (min-width: 480px),
  only screen and (max-width: 479px) {
  .row ul li {
    font-size: 14px;
  }
  .widhtFirstli{
    width: 40% !important;
  }
}

.title ul li {
  padding: 15px 13px;
}

.row ul li {
  padding: 0 0 7px 0;
}
article.row,
.row.title {
  padding: 20px 0;
  height: 60px;
  font-size: 0;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-out;
  margin-left: calc(0.1 * var(--bs-gutter-x));
  margin-right: 0;
}
article.row:hover {
  height: 16rem;
  background-color: #00000012;
}
@media only screen and (max-width: 767px) {
  article.row:hover {
    height: 100%;
  }
}
@media only screen and (max-width: 359px) {
  .row:hover {
    height: 105px;
  }
}

.title {
  padding: 15px 0 5px 0;
  height: 65px;
  font-size: 0;
  background-color: #001B71;
  color: white;
}

.transitionLink {
  background-color: #00000012;
  border-radius: 20px;
  padding: 10px 0 !important;
}

.row-fadeIn-wrapper:hover {
  height: 35px;
}
@media only screen and (max-width: 767px) {
  .row-fadeIn-wrapper:hover {
    height: 55px;
  }
}

@media only screen and (max-width: 359px) {
  .row-fadeIn-wrapper:hover {
    height: 75px;
  }
}

.fadeIn {
  padding: 20px 0;
  font-size: 0;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.fadeIn:hover {
  background-color: rgba(23, 23, 23, 0.9); /* lighten(var(--dark-bg), 10%) */
}
ul.more-content li {
  position: relative;
  font-size: 13px;
  margin: 0;
  padding: 10px 13px;
  display: block;
  height: 88%;
  width: 100%;
  color: rgba(84, 86, 90, 0.76); /* darken(var(--text), 50%) */

  /* display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.row ul li.widhtFirstli {
  width: 35%;
}

@media only screen and (max-width: 767px) {
  ul.more-content li {
    font-size: 11px;
  }
}
.small {
  color: rgba(128, 128, 128, 0.54); /* darken(var(--text), 60%) */
  font-size: 10px;
  padding: 0 10px;
  margin: 0;
}

.wrapper > main {
  border-radius: 20px;
}

.responsive-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px; /* Ancho por defecto */
}

@media (min-width: 768px) {
  .responsive-truncate {
    max-width: 200px; /* Cambia el ancho en pantallas medianas */
  }
}

@media (min-width: 992px) {
  .responsive-truncate {
    max-width: 300px; /* Cambia el ancho en pantallas grandes */
  }
}

@media only screen and (max-width: 767px) {
  .small {
    display: none;
  }
}
@keyframes fadeIn {
  from {
    background: var(--light-bg);
    opacity: 0;
    padding: 0;
  }
  to {
    background: rgba(0, 0, 0, 1); /* darken(var(--light-bg), 80%) */
    opacity: 1;
    padding: 0 0 65px 0;
  }
}

@keyframes fadeOut {
  from {
    background: rgba(0, 0, 0, 1); /* darken(var(--light-bg), 80%) */
    opacity: 1;
    height: 65px;
  }
  to {
    background: var(--light-bg);
    opacity: 0;
    height: 0;
  }
}

/* Media queries */
@media (min-width: 1588px) {
  article.row {
    height: 60px; /* Altura para pantallas grandes */
  }
  article.row:hover {
    height: 9rem;
  }
  article.row:hover > ul.d-flex{
    margin-bottom: 2rem;
  }
}

@media (min-width: 1087px) and (max-width: 1587px) {
  article.row {
    height: 65px; /* Altura para pantallas medianas */
  }
  article.row:hover {
    height: 9rem;  
  }
  article.row:hover > ul.d-flex{
    margin-bottom: 2rem;
  }
}
@media (min-width: 789px) and (max-width: 1086px) {
  article.row {
    height: 90px; /* Altura para pantallas medianas */
  }
  article.row:hover {
    height: 9rem; 
  }
  article.row:hover > ul.d-flex{
    margin-bottom: 2rem;
  }
}

@media (max-width: 788px) {
  article.row {
    height: 90px; /* Altura para pantallas pequeñas */
  }
  article.row:hover {
    height: 10rem;  
  }
  article.row:hover > ul.d-flex{
    margin-bottom: 2rem;
  }
  .p-linkup{
    padding-top: 0;
  }
}
@media (max-width: 576px) {
  .justify-content-sm-around {
      justify-content: space-around !important;
  }
  article.row:hover > ul.d-flex{
    margin-bottom: 3rem;
  }
}