/* switch */
input.check-box[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

label.label-check {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    /* height: 100px; */
    background: #FFFFFF;
    display: block;
    border-radius: 100px;
    position: relative;
}

label.label-check::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    background: #0071CE;
    border-radius: 90px;
    transition: 0.3s;
}

input.check-box:checked + label.label-check {
    background: #0071CE;
}

input.check-box:checked + label.label-check::after {
    background: #FFFFFF;
}

input.check-box:checked + label.label-check::after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

label.label-check:active::after {
    width: 30px;
}
.data-users > ul > li > input + .label-check{
    height: 25px;
} 
/* checkbox */
  .form-group {
    display: block;
    margin-bottom: 15px;
  }
  
  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-group label {
    position: relative;
    cursor: pointer;
  }
  
  .form-group label:before {
    content:'';
    background-color: white;
    border: 2px solid #0079bf;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 7px;
  }
  
  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .w-0{
    width: 0 !important;
  }

  .data-users {
    padding: 20px 0;
    height: 60px;
    font-size: 0;
    position: relative;
    overflow: hidden; 
    transition: all 0.2s ease-out;
    margin-left: calc(0.1* var(--bs-gutter-x));
    margin-right: 0;
    background: #00000012;
    border-radius: 20px;
  }
/* --------------- */

.send-credentials {
  height: 50px;
  width: 50px;
  margin-top: -14px;
  cursor: pointer;
}

/* -----Paginador------ */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 8px;
}

.pagination-button {
  padding: 8px 12px;
  width: 40px;
  border: 1px solid #001B71;
  background-color: #001B71;
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-button-prev-next {
  padding: 8px 12px;
  border: 1px solid #001B71;
  background-color: #001B71;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 0px 0px 4px 2px #0033FF;
}

.pagination-button.active {
  background-color: #fff;
  color: #001B71;
  font-weight: bold;
  border: 1px solid #fff;
}

.pagination-button[disabled] {
  cursor: default;
  opacity: 0.6;
}
/* ------------------ */

.responsive-truncate{
  height: 22px;
}

/* --------For the costs in promo---------- */
.costo-vigente {
  position: relative; /* Relativo para anclar el hover-text */
  overflow: visible;
}

.hover-text {
  position: absolute;
  left: 500%; /* Centrado horizontal respecto al ícono */
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
  white-space: nowrap;
  background-color: #001B71;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
  z-index: 10;
}

.costo-vigente:hover .hover-text {
  visibility: visible;
  opacity: 1;
  z-index: 15;
}

.no-overflow-hidden {
  overflow: visible !important; /* Sobrescribe el overflow: hidden de la clase global */
}