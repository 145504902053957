.--config-container {
    background: #00000012;
    border-radius: 25px;
}
.cita-option {
    height: 39px;
    border-radius: 10px !important;
}
.btn-primary.addbaseConocimineto {
    height: 39px;
    border-radius: 10px !important;

}
.border2 {
    border: solid 1px #0047BA !important;
}

.--btn-sync {
    border-radius: 10px !important;
}
.--docs-container {
    background-color: #B7B7B7;
}

.--btn-docs {
    border-radius: 10px !important;
}

.bi-folder-plus {
    background-color: #001B71;
}

.searchBar > .input-group {
    box-shadow: none;
    border-radius: 0;
    border-bottom: 2px solid white;
}

input.--back-style::placeholder {
    color: white !important;
    opacity: 1; /* Asegúrate de que el color sea completamente visible */
}
.modal-content{ 
    background: linear-gradient(177.44deg, #0047BA 3.1%, #001B71 97.87%) !important;
    color: white !important;

}

.MuiSlider-root.MuiSlider-marked > span.MuiSlider-markLabel {
    color: white;
}

.bi-folder-plus.--add-doc {
    background-color: white;
    color: #001B71;
}

.modal-footer {
    border-top: none !important;
}