body, html  {
    background-color: #e5e5e9 !important;
}

.p-login {
    padding-top: 7em;
}

.centered-placeholder::placeholder {
    text-align: center;
}

.centered-input-container {
    text-align: center;
    display: flex;
    justify-content: center;
}

.loading {
    max-height: 18px;
}

.checkout-form {
    max-width: 500px;
    margin: auto;
}

.card-element-wrapper {
    padding: 10px !important;
    border: 1px solid #ced4da !important;
    border-radius: 12px !important;
    background-color: #f8f9fa !important;
    transition: box-shadow 0.3s, border-color 0.3s;
}

.bg-coparmex-login {
    background-color: #50649c !important;
    border: 0 !important;
    color: white !important;
}

.bg-coparmex-login::placeholder {
    color: rgba(255, 255, 255, 0.7) !important; /* White color with 70% opacity */
    opacity: 1; /* Ensures the placeholder color is applied correctly */
}

.card-element {
    width: 100%;
}

.bg-login-animation  {
    background: linear-gradient(40deg, #3344d8, #191975,#191975, #3344d8);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    width: 100%;
    height: 100vh !important;
}

.bg-transparent-coparmex {
    background-color: #50649c36;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.--style-button-login {
    padding-right: 3rem;
    padding-left: 3rem;
}
@media (max-width: 576px) {
    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .--style-button-login {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
}
