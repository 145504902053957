.affiliation-active {
  padding: 8px 12px;
  border: 1px solid #001B71;
  background-color: #001B71;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 0px 0px 4px 2px #0033FF;
}

/* Botón sin acción */
.pagination-button-prev-next.no-action {
  cursor: not-allowed;
  /* Cambia el cursor para indicar que no se puede interactuar */
}

/* Botón gris pero funcional */
.affiliation-inactive {
  padding: 8px 12px;
  border-radius: 20px;
  background-color: #ccc;
  /* Fondo gris */
  /*color: #333; /* Color de texto */
  color: #fff;
  box-shadow: none;
  /* Sin sombra */
  border: .5px solid #999;
  /* Borde gris */
  cursor: pointer;
  /* Cursor interactivo */
}

.affiliation-buttons {
  display: flex;
  gap: 10px;
  /* Espacio entre los botones */
  margin-top: 20px;
  /* Espacio respecto al texto superior */
}

.affiliation-upload {
  padding: 8px 12px;
  border: 1px solid #001B71;
  background-color: #001B71;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 0px 0px 4px 2px #0033FF;
  /*cursor: default; /* Elimina la interacción visual del cursor */
  /*pointer-events: none; */
  margin-left: auto;
  /* Empuja el botón hacia la derecha */
  display: block;
}

.input-cost:focus {
  box-shadow: none !important;
}

.div-focus:focus,
.div-focus:active,
.div-focus:hover {
  box-shadow: 0 0 0 .25rem rgb(255 255 255 / 29%) !important;
}

.border-title {
  border-radius: 20px;
}

.scroll-show::-webkit-scrollbar {
  height: 8px;
  /* Cambia esto para ajustar el tamaño de la barra */
}

.scroll-show::-webkit-scrollbar-thumb {
  background-color: #616161;
  border-radius: 5px;
}

/* Fuerza a que siempre se muestre el scrollbar en navegadores modernos */
/* body, html {
  overflow: scroll;
  overflow-x: auto;
} */

.arrow-button {
  position: absolute;
  top: 90%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}
