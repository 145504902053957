// custom.scss
$white: #ffffff;

$theme-colors: (
    "light":      #f2f2f2,
    "dark":       #181b26,
    "primary":    #40a7eb,
    "secondary":  #9698a0,
    "info":       #5e30eb,
    "success":    #1ab063,
    "warning":    #e9cd19,
    "danger":     #d02225,
);

// Import Bootstrap functions and variables
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

// Override h1, h2, and h3 font sizes
$font-sizes: map-merge(
  $font-sizes,
  (
    'h1': 3rem,  // Custom size for h1
    'h2': 2.5rem,    // Custom size for h2
    'h3': 2rem,    // Custom size for h3
  )
);

// Override the border radius
$border-radius: 0.7rem; // Adjust this value as needed

// Override the input focus color and box-shadow
$input-focus-border-color: #76b5e0; // New color for border in focus
$input-focus-box-shadow: 0 0 0 0.2rem rgba(#76b5e0, .25);

// Import the rest of Bootstrap
@import '~bootstrap/scss/bootstrap';

// Custom checkbox styles
.form-check-input:checked {
  background-color: map-get($theme-colors, "success");
  border-color: map-get($theme-colors, "success");
}

.form-check-input:focus {
  border-color: map-get($theme-colors, "success");
  box-shadow: 0 0 0 0.25rem rgba(map-get($theme-colors, "success"), .25);
}

.form-check-input:not(:checked):focus {
  border-color: map-get($theme-colors, "secondary"); 
  box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, "secondary"), .5);
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28211,211,211,0.7%29'/%3e%3c/svg%3e");
}
