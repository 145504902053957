.cards-style {
    background-color: #00000012;
    border-radius: 20px;
    transition: box-shadow 0.3s ease;
}

.cards-style:hover {
    cursor: pointer;
    box-shadow: 0px 0px 27.5px 1px #0033FF;

}

span.text-primary {
    color: #0047BA !important;
}

.description-style {
    color: #636363;
}

.min-h {
    min-height: 54px;
}

.min-h-cards {
    min-height: 175px;
}

.min-h-cards-cost {
    min-height: 9rem;
}

.bg-edit {
    background: #fff !important;
}

.v-align-span {
    height: 40px;
    display: table-cell;
    vertical-align: middle;
}

.btn-outline-span {
    background-color: transparent;
    border: solid 2px #0047BA !important;
    color: #0047BA !important;
}

.finance-table ul li {
    width: 100% !important;
}

.pickers .MuiInputBase-root {
    background-color: #081c78 !important;
}

.pickers .MuiInputBase-input {
    height: 10px !important;
    padding-bottom: 20px;
}

.MuiChartsLegend-root {
    display: none;
}

.expanded ul{
    margin-bottom: 3rem!important;
}

.more-content ul{
    height: auto;
    min-height: 1rem;
}

.art-style {
    padding: 10px 0;
    height: 60px;
    font-size: 0;
    position: relative;
    overflow: hidden;
    transition: all 0.2s ease-out;
    margin-left: calc(0.1* var(--bs-gutter-x));
    margin-right: 0;
    background: #00000012;
    border-radius: 20px;
}

.finance-table article.art-style {
    height: 60px;
    /* Altura inicial */
    overflow: hidden;
    transition: height 0.3s ease;
}

.finance-table article.art-style.expanded {
    height: auto;
    /* Se expande según el contenido */
    overflow: visible;
}

/* .finance-tab{
    margin-top: 32px;
} */

/*Estilos de Manu  */
.same-height-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.same-height-cards .card-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
    min-height: 250px; /* Ajusta según sea necesario */
}

.same-height-cards .card-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.same-height-cards .card-status {
    white-space: nowrap;
}
