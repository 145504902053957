.tab-content {
    padding: 15px;
    transition: 0.3s ease all;
    background-color: #54565ac2;
    border-radius: 7px;
    overflow: hidden;
    color: #fff;
}

.btn-blue {
    background-color: #0047ba !important;
    color: #fff !important;
}

.vr {
    color: #fff;
    opacity: 1 !important;
}

.linkup-custom {
    background: #000;
    border: solid 2px;
    border-color: #0047ba;
    border-radius: 7px;
}

.linkup-tag {
    background: #000;
    border: solid 2px;
    border-color: #0047ba;
}

.linkup-filter {
    background: #c8c8c8;
}

ul {
    list-style-type: none;
}

.circle-blue {
    display: inline-block;
    background-color: transparent;
    border: 2px solid #0047ba;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
}

.circle-green {
    display: inline-block;
    background-color: transparent;
    border: 2px solid #00ba3b;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
}

.text-small{
    font-size: 12px;
}


/*Linkup Modal*/
/* .modal-body {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    overflow: hidden;
} */

/* Ajustar cada slide del perfil */
.profile-slide {
    padding: 15px;
    box-sizing: border-box;
    text-align: left;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-slide h3 {
    margin-bottom: 10px;
    color: #333;
}

.profile-slide p {
    margin-bottom: 8px;
    font-size: 15px;
    color: #555;
}

/* Ajustar carrusel para que el contenido esté bien alineado */
.slick-list {
    padding: 0 !important; /* Quitar padding extra */
    overflow: hidden; /* Asegurarse de que no se desborde el contenido */
}

.slick-track {
    display: flex;
}

.slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: auto;
}

/* Flechas de navegación del carrusel */
.slick-prev, .slick-next {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
    color: white;
}

.slick-prev:hover, .slick-next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.linkup-bg{
    background: linear-gradient(to top, #adcbf7, #c6d6fa, #ffffff, #ffffff, #ffffff);
    /* min-height: calc(100vh - 48px); */
}

.li-w{
    width: 100% !important;
}
.bi-folder-plus{
    background-color: #0047BA;
    color: #fff;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}
.responsive-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px; /* Ancho por defecto */
  }
  @media (min-width: 768px) {
    .responsive-truncate {
      max-width: 200px; /* Cambia el ancho en pantallas medianas */
    }
  }
  @media (min-width: 992px) {
    .responsive-truncate {
      max-width: 300px; /* Cambia el ancho en pantallas grandes */
    }
  }

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-button {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: inherit;
    outline: none;
}

/* Estilos para el tooltip */
.tooltip-button::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -40px; /* Ajusta para la posición vertical */
    left: 50%;
    transform: translateX(-50%);
    background-color: #001B71;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
    z-index: 1000;
}

/* Flecha del tooltip */
.tooltip-button::before {
    content: "";
    position: absolute;
    top: -12px; /* Coloca la flecha debajo del tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #001B71 transparent transparent transparent; /* Cambia el color del borde superior */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
}

/* Mostrar tooltip al pasar el cursor */
.tooltip-button:hover::after,
.tooltip-button:hover::before {
    opacity: 1;
    visibility: visible;
}

.searchBar > .input-group{
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 15px;
}
.searchBar > .input-group > .input-group-text{
    background-color: #F7F7F7;
    border: none;
}
.searchBar >.input-group > input{
    background-color: #F7F7F7;
    border: none;
    padding-left: 0;
}

@media (max-width: 788px) {
    .btn-linkup{
      font-size: 10px !important;
    }
    .--w-75{
        width: 75% !important;
    }
    .tooltip-container{
        width: 25% !important;
    }
    div.tooltip-container > button.tooltip-button.btn-linkup{
        font-size: 20px !important;
    }
    .searchBar{
        margin-top: 1rem !important;
    }
}
@media (min-width: 789px) and (max-width: 1086px) {
    .btn-linkup{
        font-size: 10px !important;
    }
    div.tooltip-container > button.tooltip-button.btn-linkup{
        font-size: 20px !important;
    }
}
@media (min-width: 992px)  {
    div.tooltip-container{
        width: 7% !important;
        margin-right: 10px;
    }
}
@media (max-width: 992px) {
    .searchBar{
        margin-top: 1rem !important;
    }
}
@media (min-width: 1920px)  {
    div.tooltip-container{
        width: 4% !important;
        margin-right: 10px;
    }
}
.resultado-vinculacion{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25%;
    background-color: #001B71;
    color: white;
    border-radius: 20px;
}

.li-w {
    background-color: #00000012;
    border-radius: 20px;
}

.text-small {
    font-size: 14px;
}

.s-color {
    color: #0047BA;
}

.perfil-empresa{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #001B71;
    color: white;
    border-radius: 20px;
}
