@font-face {
    font-family: Poppins-Black;
    src: url('../fonts/Poppins-Black.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('../fonts/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-Italic;
    src: url('../fonts/Poppins-Italic.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('../fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: Poppins-Regular;
    src: url('../fonts/Poppins-Regular.ttf');
}

body {
    font-family: Poppins-Regular;
}

.linkup-div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    border-radius: 10px;
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.linkup-div:focus,
.linkup-div:active,
.linkup-div:target {
    border-color: #76b5e0;
    outline: 0;
}

.pad-web {
    padding: 0.43rem !important;
}

.invalid-cat {
    border-color: var(--bs-form-invalid-border-color);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.linkup-tag {
    display: inline-flex;
    align-items: center;
    padding: 5px;
    margin: 2px;
    background-color: #ccc;
    border-radius: 3px;
}

.linkup-close {
    margin-left: 5px;
    border: none;
    background: none;
    cursor: pointer;
}

.linkup-input {
    flex: 1;
    border: none;
    outline: none;
    margin-left: 5px;
    min-width: 150px;
}

.main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 20%;
}

.terms-position {
    margin-top: auto;
}

.no-focus {
    border: none !important;
}

.no-focus:focus {
    outline: none !important;
    border-color: transparent !important;
}


/*Modal linkup*/
.profile-slide {
    padding: 20px;
}

.slick-prev,
.slick-next {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 50%;
    padding: 10px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-spinner img {
    width: 50px;
    height: 50px;
}

/*Skip linkup*/
.button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.btn-skip {
    font-size: 0.9rem;
    transition: all 0.3s ease;
    padding: 10px;
    position: relative;
}

.skip-text {
    display: inline-block;
}

.skip-hover-text {
    display: none;
}

.btn-skip:hover .skip-text {
    display: none;
}

.btn-skip:hover .skip-hover-text {
    display: inline-block;
    font-size: 1rem;
}

.slick-slider {
    margin-left: 10px;
    margin-right: 10px;
}

.text-blue {
    color: #0047ba;
}

.linkup-bg {
    background: linear-gradient(to top, #adcbf7, #c6d6fa, #ffffff, #ffffff, #ffffff);
    min-height: calc(100vh);
}

.linkup-form-bg {
    background-color: #c8c8c83f;
    border-radius: 10px;
    padding: 1rem;
}

.btn-linkup {
    background-color: #001B71 !important;
    color: #fff !important;
    border: solid 0.2rem #0071CE !important;
}

.btn-linkup:hover {
    background-color: #0047BA !important;
}

/* step-form */
/* #step-form input:focus,
#step-form button:focus {
    outline: none;
}

#step-form button:hover,
#step-form .reset:hover {
    opacity: .8;
}

#step-form button:active,
#step-form .reset:active {
    opacity: .5;
}

#step-form .container {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#step-form .steps {
    margin-bottom: 10px;
    position: relative;
    height: 25px;
}

#step-form .steps>div {
    position: absolute;
    top: 0;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    height: 25px;
    padding: 0 5px;
    display: inline-block;
    width: 80px;
    text-align: center;
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
}

#step-form .steps>div>span {
    line-height: 25px;
    height: 25px;
    margin: 0;
    color: #777;
    font-family: 'Roboto', sans-serif;
    font-size: .9rem;
    font-weight: 300;
}

#step-form .steps>div>.liner {
    position: absolute;
    height: 2px;
    width: 0%;
    left: 0;
    top: 50%;
    margin-top: -1px;
    background: #999;
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
}

#step-form .step-one {
    left: 0;
}

#step-form .step-two {
    left: 50%;
    clip: rect(0, 0px, 25px, 0px);
}

#step-form .step-three {
    left: 100%;
    clip: rect(0, 0px, 25px, 0px);
}

#step-form .line {
    width: 100%;
    height: 5px;
    background: #ddd;
    position: relative;
    border-radius: 10px;
    overflow: visible;
    margin-bottom: 50px;
}

#step-form .line .dot-move {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 15px;
    height: 15px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #ddd;
    border-radius: 50%;
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
}

#step-form .line .dot {
    position: absolute;
    top: 50%;
    width: 15px;
    height: 15px;
    left: 0;
    background: #ddd;
    border-radius: 50%;
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
    -webkit-transform: translate(-50%, -50%) scale(.5);
    -ms-transform: translate(-50%, -50%) scale(.5);
    transform: translate(-50%, -50%) scale(.5);
}

#step-form .line .dot.zero {
    left: 0%;
    background: #bbb;
}

#step-form .container.slider-one-active .dot.zero {
    background: #5892fc;
}

#step-form .line .dot.center {
    left: 50%;
    background: #bbb;
}

#step-form .line .dot.full {
    left: 100%;
    background: #bbb;
}

#step-form .slider-ctr {
    width: 100%;
    overflow: hidden;
}

#step-form .slider {
    overflow: hidden;
    width: 1200px;
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
    -webkit-transform: translate(0px) scale(1);
    -ms-transform: translate(0px) scale(1);
    transform: translate(0px) scale(1);
} */

.btn-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-linkup {
    padding-top: 7em;
}

.multi-step-form .form-control::placeholder {
    color: #ffffffbc;
    opacity: 1;
}

.multi-step-form .form-control:-ms-input-placeholder {
    color: #ffffffbc;
}

.multi-step-form .form-control::-ms-input-placeholder {
    color: #ffffffbc;
}

/*multi-step form*/
.step-container {
    position: relative;
    text-align: center;
    transform: translateY(-43%);
}

.step-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #007bff;
    line-height: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.4s ease, transform 0.4s ease;
}

.step-circle.active {
    background-color: #0047BA;
    color: white;
    transform: scale(1.2);
}

.step-line {
    position: absolute;
    top: 16px;
    left: 50px;
    width: calc(100% - 100px);
    height: 2px;
    background-color: #007bff;
    z-index: -1;
}

#multi-step-form {
    overflow-x: hidden;
}

.btnSecondary {
    background-color: #D37F7F !important;
    border-color: #B70000 !important;
}

.bi-folder-plus {
    height: 2em;
    border-radius: 10px !important;
    font-size: 25px;
}

.--divStyled {
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #B7B7B7;
    border-color: #B7B7B7 !important;
    color: white;
}

.--textPrimaryVariant {
    color: #0047BA;
}

.--textPrimaryStrong {
    color: #001B71;
}

.--stylebtnVariant {
    background-color: #9698A080 !important;
    height: 3em;
    border-color: #9698A080 !important;
}

.--stylebtnVariant::placeholder {
    color: #ffffffba!important;
    opacity: 1;
}

.--stylebtnVariant::-ms-input-placeholder {
    color: #fff!important;
}

.visionTextArea {
    background-color: #9698A080 !important;
    border-width: 10px;
}

textarea::placeholder {
    color: #969696 !important;
    opacity: 1;
}

textarea.--textAreaStyled {
    height: 135px;
    background-color: #dadada66 !important;
    border-color: rgba(var(--bs-secondary-rgb));
    border-width: 10px;
    color: #000;
}

textarea.form-control:focus {
    border-color: #969696;
    box-shadow: none;
    color: #000;
}

textarea.visionTextArea::placeholder {
    color: #f0f0f1 !important;
}

.linkup-vr {
    min-height: 100% !important;
    background-color: #081c78 !important;
    border: 1px solid #244cfc !important;
}

.profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

@media only screen and (max-width: 810px) {
    .no-vr {
        display: none !important;
    }
}

.--style-linkup-inputs{
    background-color: #dadada66 !important;
    border-color: rgba(var(--bs-secondary-rgb))!important;
    border-width: 4px!important;
    color: #000!important;
}