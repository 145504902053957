.luisa-bubble-postion {
    position: fixed;
    z-index: 101;
    bottom: 0;
    width: 10%;
}

.luisa-bubble-btn {
    margin-bottom: 40px;
    margin-left: 250px;
    width: 260px;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 2px 2px 15px rgba(75, 132, 255, 0.646);

}

.luisa-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
}

.bg-custom-1 {
    background: linear-gradient(120deg, #00d5ff, #3e4cdf, #1242a8, #00d5ff);
    background-size: 200% 200%;
    transition: background-position 1s ease, transform 1s ease;
    background-position: 0% 50%;
}

.bg-custom-1:hover {
    background-position: 100% 50%;
}

.luisa-bubble-btn-text {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(236, 236, 236);
}


.luisa-detail-postion {
    position: fixed;
    z-index: 100;
    bottom: 110px;
    width: 10%;
}

.luisa-detail {
    position: relative;
    background-color: rgba(255, 255, 255, 0.53);
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.212);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    width: 550px;
    max-height: 700px;
    max-width: 90vw;
    margin-left: 250px;
    padding-bottom: 50px;
    transform: translateY(100%);
    /* Initially hidden */
    opacity: 0;
    /* Initially invisible */
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.btn-limpiar {
    background-color: #ebebeb; /* Gris claro */
    border: 1px solid #535252; /* Borde sutil */
    color: #333; /* Color de texto oscuro para contraste */
    padding: 2px 10px;
    border-radius: 12px;
    transition: background-color 0.2s ease-in-out;
}

.btn-limpiar:hover {
    background-color: #e0e0e0; /* Un tono más oscuro al pasar el mouse */
}

.luisa-messages {
    max-height: 580px;
    overflow-y: auto;
    overflow-x: hidden; /* Oculta la barra horizontal */
    padding-right: 15px; /* Agrega espacio sin cortar texto */
    word-break: break-word; /* Rompe palabras largas */
}

.luisa-detail.show {
    transform: translateY(0);
    /* Slide up */
    opacity: 1;
    /* Fade in */
}

.luisa-detail.hide {
    transform: translateY(100%);
    /* Slide down */
    opacity: 0;
    /* Fade out */
}

.luisa-input-container {
    position: absolute;
    /* Ensure positioning within its relative parent */
    bottom: 1px;
    /* Adjust spacing from the bottom */
    left: 0;
    width: 100%;
    /* Make sure it spans the full width of the parent */
    display: flex;
    /* Optional: For better alignment of child elements */
    justify-content: center;
    /* Center horizontally */
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.luisa-input {
    width: 100%;
    max-height: 50px; /* Define un límite antes de que aparezca el scroll */
    min-height: 30px; /* Altura mínima */
    overflow-y: auto; /* Scroll cuando el contenido excede la altura */
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    line-height: 1.4;
    word-break: break-word;
    resize: none; /* Evita el redimensionamiento manual */
}


.luisa-icon {
    object-fit: contain;
}

.luisa-message {
    background-color: #002f95;
    color: whitesmoke;
    padding: 10px;
    border-radius: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
    width: fit-content; /* Ajusta el tamaño al contenido */
    max-width: 90%; /* Evita que el texto se salga del contenedor */
    display: inline-block;
}

.luisa-message-2 {
    background-color: #5d84d7;
    color: whitesmoke;
    padding: 10px;
    border-radius: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
    width: fit-content; /* Ajusta el tamaño al contenido */
    max-width: 90%; /* Evita que el texto se salga del contenedor */
    display: inline-block;
}

.luisa-message-container-2 {
    justify-content: end;
    margin-top: 10px;
}

.luisa-message-container-1 {
    justify-content: start;
    margin-top: 10px;
}


/*ANIMATION LOADING*/
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.loader span {
    width: 8px;
    height: 8px;
    background-color: #FFF;
    border-radius: 50%;
    display: inline-block;
    animation: blink 1.4s infinite;
}

.loader span:nth-child(2) {
    animation-delay: 0.2s;
}

.loader span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes blink {
    0% { opacity: 0.3; }
    50% { opacity: 1; }
    100% { opacity: 0.3; }
}