/* Search bar responsive styles */
.searchBar {
    width: 100%;
    transition: all 0.3s ease;
}

/* Pagination controls */
.pagination-controls {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.pagination-button {
    padding: 8px 12px;
    margin: 0 2px;
    background-color: transparent;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.pagination-button.active {
    background-color: #001B71;
    color: white;
    border-color: #001B71;
}

.pagination-button:hover:not(.active):not(:disabled) {
    background-color: #f0f0f0;
}

.pagination-button:disabled {
    color: #999;
    cursor: not-allowed;
}

.pagination-button-prev-next {
    padding: 8px 12px;
    background-color: transparent;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.pagination-button-prev-next:hover {
    background-color: #f0f0f0;
}

.pagination-info {
    display: inline-block;
    padding: 8px 12px;
    margin: 0 5px;
    color: var(--text);
}

@media (min-width: 992px) {
    .btn-events {
        width: 25% !important;
    }
}

@media (max-width: 788px) {
    .events-content {
        overflow-x: auto;
        width: 100%;
        max-width: 100%;
        white-space: nowrap;
    }

    .row-min-width {
        min-width: 2000px;
    }

    .row-min-width ul {
        min-width: 1500px;
    }

}

@media (min-width: 789px) and (max-width: 1086px) {
    .data-users {
        padding: 45px 0;
        height: 100px;
    }
}