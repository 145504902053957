.--status-style-devolucion {
  background: #FFDBDB;
  border-radius: 25px;
  color: #DD0000;
  border: 3px solid #DD0000
}

.--status-style-pagado {
  background: #D1F7D7;
  border-radius: 25px;
  color: #197C28;
  border: 3px solid #197C28
}

.--status-style-pendiente {
  background: #FFF1D8;
  border-radius: 25px;
  color: #FFA600;
  border: 3px solid #FFA600
}

.--status-style-proceso {
  background: #fcf89e;
  border-radius: 25px;
  color: #dabb05;
  border: 3px solid #dabb05
}

/* -----Paginador------ */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 8px;
}

.pagination-button {
  padding: 8px 12px;
  width: 40px;
  border: 1px solid #001B71;
  background-color: #001B71;
  color: #fff;
  border-radius: 100%;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-button-prev-next {
  padding: 8px 12px;
  border: 1px solid #001B71;
  background-color: #001B71;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 0px 0px 4px 2px #0033FF;
}

.pagination-button.active {
  background-color: #fff;
  color: #001B71;
  font-weight: bold;
  border: 1px solid #fff;
}

.pagination-button[disabled] {
  cursor: default;
  opacity: 0.6;
}

/* ------------------ */

.newEvent .modal-content{
  background: white !important;
  color: #0047ba !important;
}

.min-h-90{
  min-height: 90%;
}