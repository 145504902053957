.--backgroun-light {
    background-color: #00000012;
    border-radius: 20px;
}

.--btn-light {
    background-color: #ffffff !important;
}

.--btn-light:hover {
    background-color: #001b71 !important;
    color: #ffffff !important;
}

.searchBar>.input-group {
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 15px;
    border-bottom: 0;
}

.--bg-light {
    background-color: #ffffff;
    border-radius: 20px;
}

.custom-modal .modal-content {
    background: #f1f1f1f2 !important;
    color: #0047ba !important;
}

.archivos_container {
    border-top: 2px solid #5985cd;
    border-bottom: 2px solid #5985cd;
}

.--shadow-style {
    backdrop-filter: blur(20px);
    box-shadow: 0px 4px 4px 0px #00000040;
}

.--style-publicacion {
    box-shadow: 0px 4px 4px 0px #00000040;
}

.heart-blue {
    color: #0047ba;
}

input[type="file"] {
    display: none;
}

.file-input-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom-file-label {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #888;
    background-color: #ffffff;
    border: 2px solid #ccc;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
}

.label-for-archivo.custom-file-label {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #121212;
    background-color: #989898;
    border: 2px solid #ccc;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
}

.custom-file-label:hover {
    background-color: #ffffff;
    border-color: #aaa;
}

.label-for-archivo.custom-file-label {
    background-color: #989898;
}

.file-label {
    font-weight: bold;
    font-size: 1rem;
    color: #333;
    margin-bottom: 5px;
    display: block;
}

.file-document {
    background-color: #e0e0e0;
    border-radius: 20px;
    color: #4e4e4e;
}

.--icon-container {
    background-color: #e0e0e0;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.--border-left {
    border-left: 1px solid #000000
}

.--img-commission {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
}


/* -----Paginador------ */
.pagination-controls .pagination li {
    all: unset; /* Eliminar todos los estilos heredados */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 8px;
}

.pagination-controls .pagination .page-link {
    padding: 8px 12px;
    width: 40px;
    border: 1px solid #001B71;
    background-color: #001B71;
    color: #fff;
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
    text-decoration: none;
}

.page-item {
    display: inline-block;
}

li .page-link-prev-next {
    width: 10rem!important;
    border-radius: 25px!important;
}

.page-link.page-link-prev-next {
    padding: 8px 12px;
    border: 1px solid #001B71;
    background-color: #001B71;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: 0px 0px 4px 2px #0033FF;
}

.page-item.active .page-link {
    background-color: #fff !important;
    color: #001B71;
    font-weight: bold;
    border: 1px solid #fff;
}

.page-link[disabled] {
    cursor: default;
    opacity: 0.6;
}

.--img-edit{
    border-radius: 25px;
    width: 16rem;
    height: 16rem;
    object-fit: cover;
}