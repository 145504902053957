/* CreateRoleModal.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegura que el overlay esté sobre otros elementos */
  }
  
  .modal2 {
    background: linear-gradient(180deg, #0047BA 0%, #001B71 100%);
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    z-index: 1001; /* Asegura que el modal esté sobre el overlay */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); /* Añade sombra para mejor visibilidad */
  }
  
  .title {
    color: white;
    margin-bottom: 10px;
  }
  
  .separator {
    border: 1px solid white;
    margin-bottom: 20px;
  }
  
  .label {
    color: white;
    font-size: 14px;
    margin-bottom: 8px;
    display: block;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    border-radius: 15px;
    border: none;
    outline: none;
    margin-bottom: 20px;
  }