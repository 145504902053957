@font-face {
    font-family: Poppins-Black;
    src: url('../fonts/Poppins-Black.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('../fonts/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-Italic;
    src: url('../fonts/Poppins-Italic.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('../fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: Poppins-Regular;
    src: url('../fonts/Poppins-Regular.ttf');
}

body {
    font-family: Poppins-Regular !important;
    overscroll-behavior: none;
}

.blur-effect {
    filter: blur(3px);
}

.logo-size {
    height: 25px;
}

.pt-main {
    padding-top: 4.5em;
}

#topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
}

.px-custom-conversation {
    padding-right: 30%;
    padding-left: 30%;
}

.btn-add {
    position: fixed;
    bottom: 20px;
    right: 0;
    width: 100%;
    z-index: 3;
}

.bg-gray {
    background-color: #eaeaea;
}

.bg-custom-1 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #0d4753, #13a06c, #13a06c, #0d4753);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    background-position: 0% 50%;
}

.bg-custom-2 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #3c445e, #11989f, #11989f, #3c445e);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    background-position: 0% 50%;
}

.bg-custom-3 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #181926, #3c445e, #3c445e, #181926);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    background-position: 0% 50%;
}

.bg-custom-4 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #14184c, #3c445e, #3c445e, #14184c);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    background-position: 0% 50%;
}

.bg-custom-5 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #4a4a4a, #b8b8b8, #ffffff, #515151);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    background-position: 0% 50%;
}

.bg-custom-conversation {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #d0d0d0, #d0d0d0, #d0d0d0, #9d9d9d);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    background-position: 0% 50%;
}

.bg-custom-1:hover,
.bg-custom-2:hover,
.bg-custom-4:hover,
.bg-custom-3:hover,
.bg-custom-5:hover,
.bg-custom-conversation:hover {
    cursor: pointer;
    background-position: 100% 50%;
    transform: scale(1.02);
}

.clickable {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.clickable:hover {
    transform: scale(1.02);
    background-color: #9698a010;
}

.modal-content {
    border: solid lightgrey 0px !important;
    border-radius: 1rem !important;
    background: rgba(255, 255, 255, 0.9) !important;
    backdrop-filter: blur(4px) !important;
    -webkit-backdrop-filter: blur(4px) !important;
}

.cita-option i {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.5s, opacity 0.2s linear;
}

.row:hover>.cita-option i {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.2s linear;
}

.btn-base:hover>.cita-option i {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.2s linear;
}


.w-10 {
    width: 10%;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.w-50 {
    width: 50%;
}

.w-55 {
    width: 50%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.w-100 {
    width: 100%;
}

.me-date {
    margin-right: 70px;
}

.me-date2 {
    margin-left: 70px;
}

.user_image {
    margin-right: 15px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.renata_image {
    margin-left: 15px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.message-item-1 {
    border-radius: 0.7rem;
    transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
    /* Adjust the properties and timing function as needed */
    background-color: #f2f2f2;
    max-width: 60%;
}


.message-item-2 {
    border-radius: 0.7rem;
    transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
    /* Adjust the properties and timing function as needed */
    background-color: #54afece9;
    max-width: 60%;
}

.conversation-item {
    border-radius: 0.7rem;
    border-bottom-left-radius: 1px !important;
    border-top-left-radius: 1px !important;
    transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
    /* Adjust the properties and timing function as needed */
}

.conversation-item:hover {
    background-color: #f2f2f2;
    cursor: pointer;
}

.conversation-item.selected-item {
    background-color: #f2f2f2;
    border-color: #181926 !important;
}

.conversation-scroll {
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
    flex: 1;
    overflow-y: auto;
    /* Habilita el scroll en la conversación */
}

.conversation-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: calc(206vh - 1120px);

}

/* Ocultar el ícono por defecto, pero mantener su espacio */
.bi-circle.hide {
    visibility: hidden;
}

/* Mostrar el ícono cuando el elemento de la conversación esté en hover */
.conversation-item:hover .bi-circle {
    visibility: visible;
}

/* Mantener el ícono visible si el elemento tiene la clase `selected` */
.conversation-item.selected .bi-circle {
    visibility: visible;
}

.icon-upload {
    font-size: xx-large;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.large-checkbox {
    width: 30px;
    height: 30px;
    transform: scale(1.5);
    cursor: pointer;
}

.form-control-custom {
    border: 0 !important;
}

.form-control-custom:hover,
.form-control-custom:focus {
    border: 0 !important;
    outline: none !important;
    /* Remove outline */
    box-shadow: none !important;
    /* Remove Bootstrap's glow effect */
}

.accordion-collapse {
    transition: height 0.35s ease;
    overflow: hidden;
}

.btn-outline-dark:hover {
    background-color: rgba(0, 0, 0, 0.131) !important;
    color: #181926 !important;
}

.border-radius-custom-1 {
    border-bottom-left-radius: 40px !important;
    border-top-left-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
    border-top-right-radius: 40px !important;
}

.border-radius-custom-2 {
    border-bottom-right-radius: 40px !important;
    border-top-right-radius: 40px !important;
}

.border-radius-custom-3 {
    border-radius: 40px !important;
}

.w-150-px {
    width: 110px;
    text-align: center;
}

.custom-height-categoria {
    height: 130px;
}

.brightness-hover {
    filter: brightness(1);
    transition: all 0.3s ease;
    /* Apply transition to all properties */
}

.brightness-hover:hover {
    filter: brightness(0.95);
}

.bg-transparent {
    background-color: transparent !important;
}


.border-orange {
    border-left: 3px solid rgb(229, 124, 78) !important;
    /* Ensure 'solid' is specified */
}

.category-badge {
    display: inline-flex;
    /* Makes the div only as wide as the content */
    border: 1px solid #f0e68c;
    /* Border color */
    background-color: #fff8dc;
    /* Background color */
    border-radius: 1.5rem;
    /* Rounded borders */
    padding: 2px 8px;
    /* Padding adjusted for better spacing */
    align-items: center;
}

.category-icon {
    margin-right: 1rem;
    /* Ajusta el margen derecho para más separación */
}

.b-vertical {
    display: block;
    padding: .125rem 0 .125rem .75rem;
    text-decoration: none;
    border-left: .125rem solid transparent;
    border-left-color: #0047BA;
}

.bg-modal-cont .modal-content {
    background-color: #fff !important;
}

#input-img>input {
    visibility: hidden;
    width: 0;
    height: 0
}

.point {
    cursor: pointer;
}

.mw-50 {
    max-width: 49% !important;
}

.mw-50:nth-child(odd) {
    margin-right: 0.25rem;
}

.btn-size {
    font-size: 0.84rem !important;
}

.gray-skill-option {
    background-color: #dadada;
    height: 120px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.1s ease;
}

.gray-skill-option:active {
    border: 1.5px solid #2f2f2f;
}

.habilidad-icon {
    width: 50px;
}

.selected {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
    transition: box-shadow 0.3s ease-in-out;
    background-color: #cecece !important;
}

.switch-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 7px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #4caf50;
    /* Color cuando está activado */
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.i-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 0 2px #eede64;
    color: #000;
    background-color: #eede64;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spin {
    animation: rotate 1s linear infinite !important;
}

.shadow-payment {
    box-shadow: 0 0 40px rgba(34, 66, 77, 0.521);
    z-index: 1000;
}

.bg-payment-custom {
    background-color: #eaeaea;
    border-radius: 45px;
    box-shadow: 0 0 40px rgba(91, 91, 91, 0.317);
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 5vh;
    margin-top: 5vh;
}

.renata-loader {
    max-height: 150px;
}

.renata-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.bouncing-dots {
    display: flex;
    justify-content: space-between;
    width: 60px;
}

.dot {
    width: 15px;
    height: 15px;
    background-color: #FF5C35;
    border-radius: 50%;
    animation: bounce 1.5s infinite;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }
}

.conversation-rounded {
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.conversation-rounded-2 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
}

.loading {
    max-height: 18px;
}

.btn-secondary {
    color: #fff !important;
}

.btn-primary {
    background-color: #081c78 !important;
    color: whitesmoke !important;
    border: 2px solid #244cfc !important;
    /* Set the border color */
    box-shadow: 0 0 10px rgba(80, 100, 156, 0.7),
        /* Outer aura */
        0 0 20px rgba(80, 100, 156, 0.5);
    /* Inner aura */
}

.text-secondary-2 {
    color: rgba(255, 255, 255, 0.7);
}

.bg-coparmex-blue {
    background: linear-gradient(180deg, #0047BA, #001B71);
    background-size: 400% 400%;
    animation: gradient 40s ease infinite;
    /* Sombra más suave y difusa hacia abajo */
}

.coparmex-logo {
    width: 300px;
}

.bg-coparmex-light {
    background-color: #cbcbcb;
}

.bg-coparmex-dark {
    background-color: #808080;
}

.text-coparmex-primary {
    color: #081c78;
}

.text-coparmex-muted {
    color: #0000003a !important;
}

.topbar-coparmex {
    position: fixed;
    z-index: 3;
}

.topbar-coparmex-2 {
    position: fixed;
    z-index: 3;
    top: 82px;
}

.btn-light {
    background-color: #D9D9D9 !important;
    border-color: #D9D9D9 !important;
}

.btn-light>a {
    color: #081c78;
}

div.btn:hover {
    background-color: #D9D9D9;
    border-color: #D9D9D9;
    color: #081c78;
}

.btn-outline-light.btn:hover>a {
    color: #081c78;
}

.text-coparmex-primary {
    color: white;
}

.topbar-coparmex-2.py-2 {
    padding-top: 1.5rem !important;
}

button.dropdown-toggle.dropToggle:hover,
button.dropdown-toggle.dropToggle:focus,
button.dropdown-toggle.dropToggle:active {
    background-color: transparent !important;
    box-shadow: none !important;
    border-color: transparent !important;
}

div.dropdown-menu.dropMenu {
    border-radius: 0 0 0 10px !important;
    width: 100%;
    transform: translate(100px, 68px) !important;
    padding: 0;
}

.dropdown-item.dropItem {
    height: 48px;
    padding: 12px !important;

}

.btnPerfil {
    border-color: #001B71 !important;
    box-shadow: none;
}

.btnCerrarSesion {
    background-color: #969696 !important;
    border-radius: 0 0 0 10px !important;
    color: white !important;
}

.btnCerrarSesion:hover {
    background-color: #B92E2E !important;
}

.cards-style:hover {
    cursor: pointer;
    box-shadow: 0px 0px 27.5px 1px #0033FF;

}

.sub-header {
    background: #00000012;
    border-radius: 20px;
}

.bg-folder-gray {
    background-color: #888888 !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.bg-folder-gray::before {
    font-size: 5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.bi-green {
    color: green;
}

.bi-red {
    color: red;
}

.bi-edit {
    color: dodgerblue;
}

.bi-blue {
    color: #001b71;
}

.MuiPickersLayout-root,
.costModal .modal-content {
    color: #fff !important;
    background: linear-gradient(0deg, #001B71, #0047BA) !important;
    animation: gradient 40s ease infinite;
    border-radius: 5px;
}

.MuiPickersLayout-root .MuiFormLabel-root,
.MuiPickersLayout-root .MuiIconButton-root,
.MuiPickersLayout-root .MuiFilledInput-input,
.MuiPickersLayout-root .MuiDateCalendar-root,
.MuiPickersLayout-root .MuiTypography-root,
.MuiPickersLayout-root .MuiPickersDay-root {
    color: #fff !important;
}

.MuiFormControl-root .MuiFormLabel-colorPrimary,
.MuiFormControl-root .MuiIconButton-root{
    color: #fff !important;
}

.pickers .MuiFormControl-root .MuiFilledInput-input {
    color: #fff !important;
}

.MuiPickersLayout-root .MuiFormControl-root {
    width: 100%;
}

.MuiPickersLayout-root .MuiOutlinedInput-root {
    height: 50px;
}

.MuiPickersLayout-root .Mui-disabled {
    color: gray !important;
}

.--cursor-pointer {
    cursor: pointer;
}

.carousel-indicators button {
    background-color: #fff;
    /* Color de fondo de los círculos */
    border-radius: 50%;
    /* Esto hace que los indicadores sean círculos */
    width: 12px !important;
    /* Ajusta el tamaño */
    height: 12px !important;
    /* Ajusta el tamaño */
}

.min-h-event {
    min-height: 11rem;
}

/* .MuiPickersLayout-root .main-cal .MuiDayCalendar-weekContainer {
    color: #001B71 !important;
} */

.main-cal .MuiDateCalendar-root {
    background-color: #c8c8c868;
}


.main-cal .MuiFormLabel-root,
.main-cal .MuiIconButton-root,
.main-cal .MuiFilledInput-input,
.main-cal .MuiDateCalendar-root,
.main-cal .MuiTypography-root,
.main-cal .MuiPickersDay-root {
    color: #0047ba;
    font-size: 13px;
    font-weight: 600;
}

.main-cal .MuiPickersCalendarHeader-labelContainer {
    font-size: 16px !important;
    text-transform: uppercase;
}

.main-cal .MuiSvgIcon-root {
    font-size: 18px !important;
}

.MuiTooltip-tooltip {
    font-size: 1.1rem !important;
    padding: 10px !important;
    text-align: center;
}