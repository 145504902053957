.-style-event.bi-folder-plus {
    background-color: transparent;
    height: unset;
    color: white;
    display: block;
    font-size: 5.5rem;
    cursor: pointer;
}

.--style-imgEvent {
    background-color: #00000080;
    border-radius: 20px;
}

.--per-gap-5 {
    gap: 3rem;
}

.--form-style {
    background-color: #00000012;
    border-radius: 20px;
}

.--form-style .--stylebtnVariant::placeholder, .--form-style textarea::placeholder {
    color: #54565A!important;
    opacity: 1;
}

@media (max-width: 1024px) {
    .--flex-md-column {
        flex-direction: column !important;
    }

    .--d-md-block {
        display: flex;
    }
}

@media (max-width: 992px) {
    .--flex-lg-column {
        flex-direction: column !important;
    }

    .--d-lg-flex {
        display: flex;
    }
}

@media (min-width: 992px) {
    .--per-gap-5 {
        gap: 1.5rem;
    }

    .--d-md-block {
        display: flex;
    }
}

@media (min-width: 1046px) {

    .--d-md-block {
        display: block;
    }
}

@media (min-width: 1800px) {
    .--d-md-block {
        display: flex;
    }
}

.MuiInputBase-root {
    background-color: #c1c2c6 !important;
    color: #54565A!important;
}

.MuiOutlinedInput-notchedOutline {
    border: none !important;
    width: 100%;
}