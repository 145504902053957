.--promo {
    display: flex;
    background: #00000080;
    color: white;
    border-radius: 25px;
    flex-direction: column;
    transition: all 0.3s ease;
}

.--promo-container {
    background: #00000012;
    border-radius: 20px;
}

@media (max-width: 576px) {
    .col-sm-6 {
        flex: 0 0 auto;
        width: 50% !important;
    }

    .col-lg-3.col-md-5.col-sm-12 {
        margin-top: 0.5rem !important;
    }

    .flex-sm-column {
        flex-direction: column !important;
    }

    .--img-promo {
        width: 100%;
    }
}

@media (min-width: 1400px) {
    div.--promo {
        flex-direction: row !important;
    }

    .--promo {
        flex-direction: row !important;
    }
    
    .--img-wrapper {
        width: auto;
        padding: 15px;
    }
    
    .--img-promo {
        width: 20rem;
        height: 20rem;
    }
}

.heart.bi-heart {
    position: absolute;
    bottom: 5%;
    right: 5%;
    font-size: 1.5rem;
    cursor: pointer;
}

.heart.bi-heart-fill {
    color: red;
    position: absolute;
    bottom: 5%;
    right: 5%;
    font-size: 1.5rem;
    cursor: pointer;
}

.heart.bi-heart:hover {
    color: red;
}

.reactEasyCrop_Container{
    margin-bottom: 15%;
}
.--img-promo {
    border-radius: 25px;
    width: 100%;
    max-width: 20rem;
    height: auto;
    max-height: 20rem;
    object-fit: contain;
}

.no-promo-message {
    background: #00000012;
    border-radius: 20px;
}

.custom-event-image {
    border-radius: 20px !important;
}

.text-events {
    color: rgb(75, 75, 75);
}

.img-fluid-custom-event {
    max-height: 390px;
    object-fit: cover;
    border-radius: 20px !important;
}

.table-hover tbody tr:hover {
    opacity: 0.7 !important; /* Cambia la opacidad de la fila */
    transition: opacity 0.3s ease !important; /* Añade una transición suave */
    cursor: pointer;
}

.description-text {
    white-space: pre-line;
}

@media (min-width: 900px) {
    .--flex-md-row {
        flex-direction: row !important;
    }
    .--style-text-oveflow.d-md-block {
        display: -webkit-box !important;
    }
}
.--style-text-oveflow {
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-clamp: 3;
}

@media (max-width: 576px) {
    .--flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }
    .--mt-4 {
        margin-top: 1.5rem !important;
    }
    .d-sm-none {
        display: none !important;
    }
}
@media (min-width: 540px) and (max-width: 1000px) {
    .--btn-descuento {
        width: 100%;
    }
    .--btn-costo {
        width: 100%;
    }
    .--mt-4 {
        margin-top: 1.5rem !important;
    }
    .--style-text-oveflow.d-md-block {
        display: -webkit-box !important;
    }
}

.--li-container {
    position: relative;
    top: -5px;
}

.--style-active {
    border: 3px solid #197C28;
    border-radius: 17px;
    background-color: #D1F7D7;
    color: #197C28;
}

.--style-cancelado {
    border: 3px solid #DD0000;
    border-radius: 17px;
    background-color: #FFDBDB;
    color: #DD0000;
}

.--style-pendiente {
    border: 3px solid #FFA600;
    border-radius: 17px;
    background-color: #FFF1D8;
    color: #FFA600;
}

.--icon-editar {
    width: 25px;
}

ul li {
    margin: 0;
    font-size: 16px;
    font-weight: normal;
    list-style: none;
    display: inline-block;
    width: 20%;
    box-sizing: border-box;
}

.data-users.--style-lista-asistentes {
    background-color: #FFFFFF5C;
}

.cita-option i {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.5s, opacity 0.2s linear;
}

.hov-ul:hover>.cita-option i {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.2s linear;
}

.hov-art:hover{
    /* border: 1px solid #001a7191; */
    box-shadow: 0px 0px 8px 1px #0033FF;
}

.--img-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px 15px 15px 15px;
}

@media (min-width: 768px) and (max-width: 1399px) {
    .--promo {
        flex-direction: column;
    }
    
    .--img-promo {
        width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    .--promo {
        flex-direction: column;
        padding: 10px;
    }
    
    .--img-wrapper {
        padding: 10px 10px 0 10px;
    }
    
    .--img-promo {
        width: 100%;
        max-height: 15rem;
    }
    
    .--promo .p-4 {
        padding: 0.75rem !important;
    }
    
    .--promo h2 {
        font-size: 1.5rem;
    }
}